import { Base64 } from 'js-base64';

export function toIdHash(str){
  return Base64.encode(String(str)).replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '');
}

export function fromIdHash(hash) {
  return Base64.decode(hash);
}

export default Base64;
