import React, {  useLayoutEffect, useState } from 'react';
import qs from 'querystring';
import {graphql} from "gatsby";
import { updateContact } from '../services/account.service';
import Spin from "../components/Spin";
import Layout from "../components/Layout";


export const query = graphql`
    query unsubPageQuery {
        settings: sanitySiteSettings {
            title
            id
            facebook
            instagram
            twitter
            _rawOpenGraph(resolveReferences: { maxDepth: 10 })
            _rawLogo(resolveReferences: { maxDepth: 10 })
        }
        footer: sanitySiteFooter {
            _rawContent(resolveReferences: { maxDepth: 10 })
            _rawLinks(resolveReferences: { maxDepth: 10 })
            _rawSlug(resolveReferences: { maxDepth: 10 })
        }
    }
`;


const Unsubscribe = ({ location, data: settingsData }) => {
  const [loading, setLoading] = useState(true);
  useLayoutEffect(() => {
    const { id } = qs.parse(location.search.substr(1));
    if (!id) return null;

    updateContact(id, {
      contact_optout_email: '1'
    })
      .then(
        () => {
          setLoading(false);
        }
      )
      .catch(
      (e) => {
        setLoading(false);
        alert(e.message);
      }
    );
  },[]);


  return (
    <Layout
      hideCta
      settings={settingsData.settings}
      footer={settingsData.footer}
      siteName={settingsData.settings.title}
      description={settingsData.settings.description}
    >
      <div className="row">
        <div className="col-xs-18 col-sm-10 col-md-9 col-md-offset-1 col-lg-9 align-justify">
          <Spin spinning={loading}>
            <h2>Unsubscribed</h2>
            <p>We are sorry to see you go! You have been immediately unsubscribed and you will not receive any more emails.</p>
          </Spin>
        </div>
      </div>
    </Layout>
  )
};

export default Unsubscribe;
