import axios from 'axios';
const API_URL = process.env.GATSBY_API_URL;

const client = axios.create({
  baseURL: API_URL
});

// Add a response interceptor
client.interceptors.response.use(
  (response) => {
    const { data } = response;
    if (data && data.error) {
      return Promise.reject({
        message: data.error.msg,
        status: data.error.code
      });
    } else {
      return data;
    }
  },
  (error) => {
    if (error.response) {
      return Promise.reject({
        data: error.response.data,
        status: error.response.status,
      });
    }
    return Promise.reject(error);
  },
);

export default client;
