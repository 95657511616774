import apiClient from './apiClient';
import qs from 'querystring';
import { toIdHash } from '../utils/base64';

export const createAccount = (payload) =>
  apiClient.post('account_api/addaccount', qs.stringify(payload));

export const loadPlans = () => apiClient.get('API/1_0/getPlanList.php', { params: { limit: 100, product_brand: 'tmg' } });

export const postUpsells = (accountId, items) =>
  apiClient.post('API/1_0/postUpsells.php', {
    account_id: toIdHash(accountId),
    offers: items
  });

export const updateContact = (id, data) =>
  apiClient.post('API/1_0/contact.php', { ...data, contact_id: id });
